
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import axios from 'axios';
import { VEmojiPicker } from 'v-emoji-picker';
import { Auth } from '@/store/modules';
import { clone } from 'ramda';

@Component({ name: 'Message', components: { VEmojiPicker }})
export default class extends Vue {
  @Ref('textarea') TextArea!: HTMLTextAreaElement;
  @Ref('browseInput') BrowseInput!: HTMLInputElement;
  @Prop() sending!: boolean;

  message = '';
  attachments: { name: string; url: string; progress?: number }[] = [];
  uploading = false;

  selectFile () {
    this.BrowseInput.value = '';
    this.BrowseInput.click();
  }

  deleteAttachment (index: number) {
    this.attachments.splice(index, 1);
    this.$forceUpdate();
  }

  sendMessage () {
    if (this.message !== '') {
      this.$emit('send', { text: this.message, attachments: clone(this.attachments) });
      this.$forceUpdate();
    }
  }

  async handleFile (e: any) {
    try {
      const file = e.target.files[0] as File;

      if (!file) {
        return;
      }

      if (file.size > 1024 * 1024 * 5) {  // 5mb
        alert('File size cannot exceed 5 megabytes!');
        return;
      }

      this.uploading = true;

      const uploadUrl = await Auth.getUploadUrl({
        type: 'attachment',
        id: `${Date.now()}-${file.name}`,
      });

      const url = `${uploadUrl.substr(0, uploadUrl.indexOf('?'))}`;

      this.attachments.push({
        url,
        name: file.name,
        progress: -1
      });

      this.$forceUpdate();

      await axios.put(uploadUrl, file, {
        onUploadProgress: (progress) => this.attachments[this.attachments.length - 1].progress = (progress.loaded / progress.total) * 100,
        headers: {
          'Content-Type': file.type,
        }
      });

      delete this.attachments[this.attachments.length - 1].progress;
      this.$forceUpdate();
    } catch (err: any) {
      console.warn(err.message);
    }
    this.uploading = false;
  }

  selectEmoji(emoji: any) {
    const el = (this.TextArea as any).$el.querySelector('textarea');
    const cursorPosition = el.selectionEnd || this.message.length;
    this.message = `${this.message.substring(0, cursorPosition)}${emoji.data}${this.message.substring(cursorPosition)}`;
    this.$nextTick(() => (this.TextArea as any).$el.querySelector('textarea').setSelectionRange(cursorPosition + 1, cursorPosition + 1));
  }

  clear () {
    this.message = '';
    this.attachments.splice(0, this.attachments.length);
    this.$forceUpdate();
  }
}
